import React from 'react';
export default function Logo(props) {
  return (
    <svg width={150} viewBox="0 0 935.4 226.8" fill="none" {...props}>
      <g>
	      <g>
		      <g>
			      <path class="st0" fill="#001E37" d="M274.8,74v29.3h55v40.8h-55v52.1H228V30.6h122.7V74H274.8z"/>
			      <path class="st0" fill="#001E37"d="M407,74v29.3h55v40.8h-55v52.1h-46.7V30.6H483V74H407z"/>
			      <path class="st0" fill="#001E37" d="M526.1,143.3c1.8,8.3,9.8,20.1,31.4,20.1c13.3,0,21.9-4.4,21.9-15.1c0-14.8-17.2-14.5-47.9-21.6
				      c-22.5-5.3-45-14.8-45-47.3c0-35.5,25.7-52.1,67.1-52.1c38.7,0,63.6,14.8,71.6,41.4l-40.8,10.9c-3-10.1-13.3-16.3-28.4-16.3
				      c-13,0-21.3,4.7-21.3,13.9c0,10.9,12.1,12.4,39.3,18c21.9,4.1,53.2,14.2,53.2,52.1c0,37.6-30.5,52.1-69.5,52.1
				      c-30.5,0-66.3-8.6-74.2-45L526.1,143.3z"/>
			      <path class="st0" fill="#001E37" d="M681.1,196.2v-56.5L620.8,30.6h53.5L691.8,64c8.3,16.3,15.1,30.8,15.4,31.1h0.9c0.3-0.3,7.1-14.5,15.4-31.1
				      l17.2-33.4h48.5l-60.9,109.1v56.5H681.1z"/>
			      <path class="st0" fill="#001E37" d="M820.1,143.3c1.8,8.3,9.8,20.1,31.3,20.1c13.3,0,21.9-4.4,21.9-15.1c0-14.8-17.2-14.5-47.9-21.6
				      c-22.5-5.3-45-14.8-45-47.3c0-35.5,25.7-52.1,67.1-52.1c38.7,0,63.6,14.8,71.6,41.4l-40.8,10.9c-3-10.1-13.3-16.3-28.4-16.3
				      c-13,0-21.3,4.7-21.3,13.9c0,10.9,12.1,12.4,39.3,18c21.9,4.1,53.2,14.2,53.2,52.1c0,37.6-30.5,52.1-69.5,52.1
				      c-30.5,0-66.3-8.6-74.2-45L820.1,143.3z"/>
		      </g>
		      <g>
			      <path class="st1" fill="#4695E6" d="M111.6,210.8c-53.7,0-97.4-43.7-97.4-97.4c0-53.7,43.7-97.4,97.4-97.4v51.3c-25.4,0-46.1,20.7-46.1,46.1
				    s20.7,46.1,46.1,46.1c15.9,0,30.5-8.1,39.1-21.6l43.4,27.3C176,193.8,145.2,210.8,111.6,210.8z"/>
		      </g>
		      <g>
			      <path class="st2" fill="#8FD7F8" d="M194,165.3l-43.4-27.3c2.7-4.3,4.6-8.9,5.8-13.8l49.9,11.9C203.9,146.3,199.7,156.2,194,165.3z"/>
		      </g>
		      <g>
			      <path class="st3" fill="#83C2F7" d="M206.3,136l-49.9-11.9c0.8-3.5,1.2-7.1,1.2-10.7c0-9.2-2.7-18.2-7.9-25.8l42.5-28.7
				    c10.9,16.1,16.6,35,16.6,54.5C209,121,208.1,128.6,206.3,136z"/>
		      </g>
		      <g>
			      <path class="st4" fill="#5AAEF9" d="M149.8,87.6c-8.6-12.7-22.9-20.4-38.3-20.4V16c32.4,0,62.6,16,80.7,42.9L149.8,87.6z"/>
		      </g>
	      </g>
      </g>
    </svg>
  );
}